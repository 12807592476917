import React from 'react';
import { Link, graphql } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import SEO from '../components/SEO';

// components
import Layout from '../components/Layout';
import Header from '../components/Header';

import css from '../css/templates/glossary.module.css';
import closeIcon from '../images/close.svg';

export default ({ data, location }) => {
  const meta = data.airtable.data;
  const term = meta.term;
  const phonetic = meta.phonetic;
  const definitionHtml = meta.definition.childMarkdownRemark.html;
  const definitionExcerpt = meta.definition.childMarkdownRemark.excerpt;
  const references = meta.references;
  const permalink = location.href;

  var referenceList;

  if (references) {
    referenceList = references.map(item => (
      <li key={item.id}>
        <a href={item.data.url} target="_blank">
          {item.data.title}
        </a>
      </li>
    ));
  }

  return (
    <ModalRoutingContext>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <div>
              <div className="closeModal">
                <Link
                  to={closeTo}
                  state={{
                    noScroll: true
                  }}
                >
                  <img src={closeIcon} />
                </Link>
              </div>
              <article className={`raised ${css.itemModal}`}>
                <h1 className={`h1 ${css.term}`}>
                  {term} <span className={css.phonetic}>/{phonetic}/</span>
                </h1>
                <div
                  className={css.definition}
                  dangerouslySetInnerHTML={{ __html: definitionHtml }}
                />
                <ul className={css.referenceList}>{referenceList}</ul>
              </article>
            </div>
          ) : (
            <Layout>
              <SEO
                title={`${term} | Cyberbiomes`}
                description={definitionExcerpt}
                permalink={permalink}
              />
              <Header />
              <article className={`raised ${css.item}`}>
                <h1 className={`h1 ${css.term}`}>
                  {term} <span className={css.phonetic}>/{phonetic}/</span>
                </h1>
                <div
                  className={css.definition}
                  dangerouslySetInnerHTML={{ __html: definitionHtml }}
                />
                <ul className={css.referenceList}>{referenceList}</ul>
              </article>
            </Layout>
          )}
        </div>
      )}
    </ModalRoutingContext>
  );
};

export const query = graphql`
  query($term: String!) {
    airtable(table: { eq: "glossary" }, data: { term: { eq: $term } }) {
      id
      data {
        term
        phonetic
        definition {
          childMarkdownRemark {
            html
            excerpt
          }
        }
        references {
          id
          data {
            id
            title
            url
          }
        }
      }
    }
  }
`;
